
/* - This is a trimmed down version of public.css (at least primarily) - */
@media screen {
/* @override
    http://localhost:8080/4/portal_css/Sunburst%20Theme/public.css
    http://localhost:8080/sprites/portal_css/Sunburst%20Theme/public.css
*/

#portal-personaltools {
    float: right;
    background: #e3e3e3;
    position: relative;
    z-index: 3; /* personaltools should be above #portal-header (with z-index: 2) */
    top: 0;
    right: 0;
    padding: 0.25em 0.5em;
    font-size: 80%;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    -webkit-border-bottom-right-radius: 0.5em;
    -webkit-border-bottom-left-radius: 0.5em;
    -moz-border-radius-bottomleft: 0.5em;
    -moz-border-radius-bottomright: 0.5em;
}
#portal-personaltools #anon-personalbar a {
    float: left;
    margin: 0 0.5em;
}

#portal-languageselector {
    float: right;
    background: #e3e3e3;
    position: relative;
    top: 0;
    right: 1px;
    z-index: 3; /* portal-header has 2 and we need to be above to be clickable */
    padding: 0.25em 0.5em;
    font-size: 80%;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    -webkit-border-bottom-right-radius: 0.5em;
    -webkit-border-bottom-left-radius: 0.5em;
    -moz-border-radius-bottomleft: 0.5em;
    -moz-border-radius-bottomright: 0.5em;
    max-width: 50%;
}

/* Collapsible elements in search_form */

.collapsible .collapser.collapsed {
    padding-right: 14px;
    background: url('arrowRight.png') center right no-repeat;
}

.collapsible .collapser.expanded {
    padding-right: 14px;
    background: url('arrowDown.png') center right no-repeat;
}
/* @end */

/* @group LiveSearch */

#LSResult {
    position: absolute;
    right: 0;
    background: White;
    margin: -1.5em 0 0 -7em;
}

#LSResult fieldset {
    padding: 1em 0 0;
    border: 0.1em solid #ccc;
}

#livesearchLegend {
    background-color:#ccc;
    padding:0.3em;
}

#LSResult .LSDescr {
    font-size: 85%;
    color: #666;
}

.LSRow,
#LSNothingFound {
    display: block;
    padding: 0.5em 1em;
    white-space: normal;
    width:28em;
}

.LSHighlight {
    background: #ddd;
}

/* @end */


/* @group Portlets */

dl.portletError {
    background-color: #FFA500;
}

div.portletAssignments div.portletHeader {
    padding: 0.42em 1em;
    background: #ddd;
    line-height: normal;
}
dl.portlet dt.hiddenStructure {
    padding: 0;
}
div.portletAssignments {
    font-size: 0.8em;
    line-height: 2em;
}

div.portletAssignments .managedPortletActions {
    float: right;
}
div.portletAssignments .managedPortletActions a {
    margin: 0 0.25em;
    border: none;
}

dl.portletError dd {
    padding: 1em;
}

div.managePortletsLink,
a.managePortletsFallback {
    text-align: center;
    padding: 0.25em 0.5em;
    font-size: 80%;
    margin: 0 auto 1em;
    background: #ddd;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
}
a.managePortletsFallback {
    display:inline-block; /* in order for our vertical margins to work on inline element */
}
#content a.managePortletsFallback {
    display: block;
    border-bottom: none !important;
}

#portlet-prefs a {
    border: none;
    line-height: 2em;
}

/* @end */

/* @group Navigation portlet */

dl.portlet ul.navTree li a {
    border: none;
    display: block;
    zoom: 1;
}

dl.portletNavigationTree dd.portletItem {
    padding: 0;
}

dl.portlet ul.navTreeLevel0 > .navTreeItem {
    padding: 0.25em 1em;
    border-bottom: 0.1em dotted #ddd;
    line-height: 2em;
}

dl.portlet ul.navTreeLevel1 .navTreeItem {
    padding-left: 0.5em;
    line-height: 2em;
}

dl.portlet ul.navTree .navTreeCurrentItem {
    background-color: #ddd;
    border-left: 1em solid #ddd;
    margin-left: -1em;
    margin-right: -1em;
}

/* @end */

/* @group Data tables */

table.listing,
table.plain {
    border-spacing: 0;
    border-collapse: collapse;
}

table.invisible td,
table.invisible th,
table.plain td,
table.plain th,
table.listing td,
table.listing th {
    padding: 0.5em 1em;
    vertical-align: top;
}
table.plain th,
table.plain td {
    border: 1px solid #ddd;
}

table.listing td.listingCheckbox,
table.listing th.listingCheckbox {
    text-align: center;
}

table.listing {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
table.listing .top {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
table.listing th {
    text-align: left;
    color: #666;
    border: 0.1em solid #e7e7e7;
    border-style: solid solid none;
    background: #ddd;
}
table.listing tr.odd th {
    border: 0.1em solid #fff;
    border-style: solid solid none none;
}
table.listing tbody th {
    font-weight: bold;
}
table.listing tbody tr {
    text-align:left;
}
table.listing tbody tr.odd {
    background: #eee;
}
table.listing tbody tr td {
    background: transparent;
}
table.listing tbody tr.odd td {
    background: #eee;
}
table.listing td {
    border-right: 1px solid #ddd;
}
.icons-on table.listing td {
    line-height: 2em;
}
table.listing a {
    border: none;
    display: inline-block;
    border-bottom: none !important;
}

table.listing td.draggable {
    text-shadow: White 1px 1px 1px;
    background: #ddd !important;
    border-bottom: 1px solid white;
    padding: 0.4em 0.4em 0 0.5em;
}
/* Vertical table data listings */
table.vertical {
}
table.vertical th {
    text-align: right;
}
table.vertical td {
    border-top: 0.1em solid #e7e7e7;
    padding: 0.5em;
}
/* Grid table listings */
table.grid td,
table.grid th {
    border: 1px solid #e7e7e7;
    padding: 0.5em;
}

.dragindicator {
    outline: 2px solid #FFD700;
}

#foldercontents-order-column {
    padding: 0;
}

div.listingBar {
    font-size: 80%;
    margin: 1em 0;
}

div.listingBar .next {
    display: inline-block;
    float: right;
}

div.listingBar .previous {
    display: inline-block;
}

div.listingBar a {
    display: inline-block;
    padding: 0.25em 0.5em;
    border: 0.1em solid #ccc;
}

div.listingBar a:hover {
    color: White;
    background-color: #329fd7;
    border-color: #329fd7;
}

/* @end */

/* @group Status messages */

dl.portalMessage {
    margin: 1em 0;
    font-size: 80%;
    border: 1px solid #996;
    background-color: #ffffe3;
    clear:both;
}
dl.portalMessage a,
#content dl.portalMessage a {
    color: black;
    border-bottom: 1px solid #888;
}
dl.portalMessage dt {
    background-color: #996;
    font-weight: bold;
    float: left;
    margin: 0 0.5em 0 0;
    padding: 0.5em 0.75em;
    color: White;
    line-height: 1.25em;
}
dl.portalMessage dd {
    padding: 0.5em 0.5em;
    margin: 0;
    line-height: 1.25em;
}
dl.warning {
    border-color: #d80;
}
dl.warning dt {
    background-color: #d80;
}
dl.error {
    border-color: #d00;
}
dl.error dt {
    background-color: #d00;
}
dl.warning {
    border-color: #d80;
}
dl.warning dd {
    background-color: #fd7;
}
dl.error {
    border-color: red;
}
dl.error dd {
    background-color: #fdc;
}

/* Additional specificity for when status is shown inside #content */
#content dl.portalMessage {
    font-size: 100%;
}
#content dl.portalMessage dt {
    margin: 0 0.5em 0 0;
    padding: 0.5em 0.75em;
}
#content dl.portalMessage dd {
    margin: 0;
}

/* @end */

/* @group Editing */

/* @group Tabs */

/* Color differences between the two tab types */
#content ul.formTabs {
    background-color: #ddd;
    border: 0.3em solid #ddd;
}
#edit-bar {
    background-color: #75ad0a;
    border: 0.25em solid #75ad0a;
    font-size: 80%;
    min-height: 2em;
    margin-bottom: 1em;
    padding-top: 0.05em;
}
#content-views {
    float: left;
    background-color: #75ad0a;
}
/* fix shift on content views list under ie6 */
/* .ie6 is set on <html> by Modernizr library */
.ie6 ul#content-views{
    margin: -2px -3px 0px -3px;
}
#content-views a {
    color: White;
}

/* Shared CSS between the two tab types */
#edit-bar,
#content ul.formTabs,
#content-core ul.formTabs {
    display: block;
    border-style: solid solid none solid;
    margin-left: 0;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    -webkit-border-top-right-radius: 0.5em;
    -webkit-border-top-left-radius: 0.5em;
    -moz-border-radius-topleft: 0.5em;
    -moz-border-radius-topright: 0.5em;
}
#content li.formTab,
#content-core li.formTab {
    display: inline;
    margin-right:0.25em;
}
#content-views a,
#content li.formTab a,
#content-core li.formTab a {
    /* this isn't supported by all browsers, so we keep it in a separate rule */
    display: inline-block;
}
#content-views a,
#content li.formTab a,
#content-core li.formTab a {
    line-height: 2em;
    padding: 0 0.75em;
    text-decoration: none;
    border: none;
    margin: 0 ;
}
#content-views li.selected a,
#content li.formTab a.selected,
#content-core li.formTab a.selected {
    font-weight: bold;
}
#content-views li.selected a,
#content-views li a:hover,
#content li.formTab a.selected,
#content li.formTab a:hover,
#content-core li.formTab a.selected,
#content-core li.formTab a:hover {
    background: White;
    color: Black;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    -webkit-border-top-right-radius: 0.5em;
    -webkit-border-top-left-radius: 0.5em;
    -moz-border-radius-topleft: 0.5em;
    -moz-border-radius-topright: 0.5em;
}
#content li.formTab a.notify,
#content-core li.formTab a.notify {
    background-color: red;
    color: White;
}
#content li.formTab a.required span:after,
#content-core li.formTab a.required span:after,
div.field span.required:after {
    content: "■";
    color: Red;
    padding-left: 0.2em;
}
#content fieldset.formPanel,
#content-core fieldset.formPanel {
    border: none;
}
#content fieldset.formPanel.hidden,
#content-core fieldset.formPanel.hidden {
    display: none;
}
#content div.formControls input.hidden,
#content-core div.formControls input.hidden {
    display: none;
}

/* @end */

/* @group Pulldown menus */

/* @group Personal tools pulldown */
#portal-personaltools dl {
}
#portal-personaltools dt {
    font-weight: normal;
}
#portal-personaltools dt.actionMenuHeader a:after {
    content: " ▼";
}
#portal-personaltools dd {
    right: 0;
    padding-top: 0.5em;
}
#portal-personaltools dd {
    background: #e3e3e3;
    padding-bottom: 0.5em;
}
#portal-personaltools dd a {
    line-height: 2em;
    padding: 0 0.75em;
}
#portal-personaltools dd a:hover {
    background: #205c90;
    color: White;
}

/* @end */

/* @group Content pulldowns */

#contentActionMenus dl.actionMenu a,
#contentActionMenus dl.actionMenu.activated dd {
    background-color: #75ad0a;
    color: White;
    z-index: 5;
}
#contentActionMenus dl.actionMenu.activated dd {
    border-bottom: 0.5em solid #75ad0a;
        right: -3px;
}
#contentActionMenus dl.actionMenu.activated dd a:hover,
#contentActionMenus dl.actionMenu.activated dd .actionMenuSelected {
    background-color: White !important;
    color: #666;
}
#contentActionMenus dl.actionMenu {
    line-height: 2em;
}

#folderHeader, #contextHeader {
        border-bottom: 2px solid white;
}

#contextHeader { margin-top: 5px; }


/* @end */

#contentActionMenus {
    float: right;
    background: #75ad0a;
    margin: 0;
    position: relative;
    top: -0.23em;
    right:-0.24em;
    padding: 0.1em 0.24em 0 0;
    border-radius: 0.5em;
    border-top-left-radius: 0;
    -webkit-border-radius: 0.5em;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 0.5em;
    -moz-border-radius-topleft: 0;
}

.actionMenuContent {
    padding: 0 0.25em;
}

dl.actionMenu {
    float: right;
    white-space: nowrap;
    position: relative;
    margin-left: 0.5em;
}
dl.actionMenu a,
dl.actionMenu div {
    display: block !important;
    white-space: nowrap;
}
dl.actionMenu dt {
    font-weight: normal;
}
span.arrowDownAlternative {
    font-size: 80%;
}
dl.actionMenu.deactivated dt {
    margin: 0 0.25em;
}
dl.actionMenu.deactivated dd {
    display: none;
}
dl.actionMenu.activated dd {
    position: absolute;
    width: auto;
    min-width: 100%;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    -webkit-border-bottom-right-radius: 0.5em;
    -webkit-border-bottom-left-radius: 0.5em;
    -moz-border-radius-bottomleft: 0.5em;
    -moz-border-radius-bottomright: 0.5em;
}
dl.actionMenu.activated dt a,
dl.actionMenu.activated dd a,
dl.actionMenu.activated dd div {
    margin: 0 0.25em;
}

/* @end */

/* @group Control panel */

#setup-link {
    font-size: 90%;
}

#content ul.configlets {
    margin: 1em 0;
    list-style-image: none;
    list-style: none;
}
#content ul.configlets li {
    margin-bottom: 1em;
    list-style: none;
    display: block;
}
#content ul.configlets li a {
    text-decoration: none;
    border: none;
}
#content ul.configlets li a:visited {
    color: #436976;
    background-color: transparent;
}
#content ul.configlets li a:active {
    color: #436976;
    background-color: transparent;
}
#content ul.configlets li label {
    font-weight: bold;
}
#content ul.configletDetails {
    margin: 0em 1em 1em 4em;
    list-style-image: none;
    list-style: none;
}
#content ul.configletDetails li {
    margin-bottom: 1em;
    display: inline;
}
#content ul.configletDetails li a {
    text-decoration: none;
}
#content ul.configletDetails li label {
    font-weight: bold;
}
#content ul.configletDetails li.configletDescription {
    display: block;
    color: #76797c;
    margin: 0;
}
.inlineDisplay {
    display:inline
}
table.controlpanel-listing {
    width:100%;
}
table.controlpanel-listing td, table.controlpanel-listing th {
    font-size:120%;
}
table.controlpanel-listing dl {
    margin-top:0;
}
table.controlpanel-listing dd {
    margin-left: 1em;
}
table.controlpanel-listing dl dt a .trigger{
    font-weight:normal;
}
table .controlpanel-listing td {
    vertical-align:top;
}
table.controlpanel-listing td.checker{
    text-align:center;
}
table.controlpanel-listing th.smallcolumn {
    width:1.5em;
}
.chooser-right {
    float:right;
    margin-right:0;
    margin-bottom:0;
}
.rule-element {
    background-color:#EEF3F5;
    margin:0.5em 0pt 0.5em;
    padding:0.3em 1em 0.3em 1em;
    width:auto;
}
.rule-element dl {
}
.rule-element dl dd {
    margin-left:1em;
}
.rule-updown, .rule-operations {
    float:right;
    padding-top:0.8em;
}
/* @end */

/* @group Collapsible */
dl.collapsible {
    border: 0.1em solid #8cacbb;
    margin: 1em 0;
    padding: 0;
}
dl.collapsible dt.collapsibleHeader {
    display: block;
    float: left;
    background: White;
    line-height: 1.2em;
    vertical-align: middle;
    font-size: 90%;
    position: relative;
    top: -0.6em;
    width: auto;
    margin: 0 0 -0.6em 1em;
    padding: 0 0.5em;
}
dl.collapsible dd.collapsibleContent {
    margin: 0;
    padding: 0 1em 0.4em;
    clear: left;
}

/* for IE the following isn't needed, that's why the css2 selector is used */
dl.collapsible dd.collapsibleContent > dl {
    margin: 0;
    padding: 0;
}

dl.expandedInlineCollapsible dt.collapsibleHeader,
dl.expandedBlockCollapsible dt.collapsibleHeader {
    padding: 0 0.5em 0 2em;
    background: White url(treeExpanded.png) no-repeat 0.5em 50%;
    cursor: pointer;
}

dl.collapsedBlockCollapsible {
    border: none;
    height: 1em;
    width: auto;
}
dl.collapsedInlineCollapsible dt.collapsibleHeader,
dl.collapsedBlockCollapsible dt.collapsibleHeader {
    float: none;
    position: relative;
    margin: 0 0 0 1em;
    padding: 0 0.5em 0 2em;
    line-height: 1.2em;
    cursor: pointer;
    background: White url(treeCollapsed.png) no-repeat 0.5em 50%;
}
dl.collapsedInlineCollapsible dd.collapsibleContent,
dl.collapsedBlockCollapsible dd.collapsibleContent {
    display: none;
}
dl.collapsedInlineCollapsible {
    border: none;
    height: 1em;
    width: auto;
    display: inline;
}
dl.collapsedInlineCollapsible dt.collapsibleHeader {
    position: static;
    float: none;
    margin: 0;
    padding: 0 0 0 2em;
    line-height: 1em;
    cursor: pointer;
    display: inline;
}

/* @end */

/* @group Comments */

.comment {
    margin-top: 1.5em;
    font-size: 80%;
}

.comment .documentByLine {
    margin-left: 1em;
}

.comment .formControls {
    margin-left: 0;
}

.commentTime {
    color: #666;
}

.commentBody {
    padding: 1em;
    margin: 0.5em 0;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    box-shadow: 0 0 1em 0 #888;
    -moz-box-shadow: 0 0 0.5em 0 #888;
    -webkit-box-shadow: 0 0 0.5em #888;
}

.comment h1,
.comment h2,
.comment h3,
.comment h4,
.comment h5,
.comment h6 {
    font-weight: normal;
}

/* @end */

.reviewHistory,
.contentHistory {
    display: inline;
    color: Black;
}
.spacer {
    margin: 1em;
}

.label {
    font-weight: bold;
    display: inline;
}
.optionsToggle {
    border: 0.1em solid #8cacbb;
    color: Black;
    background-color: #dee7ec;
    font-weight: normal;
    font-size: 90%;
}

/* for highlighting of search terms */
.highlightedSearchTerm {
   background-color: #ffa;
}
dl.searchResults .highlightedSearchTerm {
    background-color: transparent;
    font-weight: bold;
}
/* for greying out inherited roles */
.noInheritedRoles {
    color: #a0a0a0;
}
/* for highlighting current items, like the language or today in the calendar */
.currentItem {
    border-collapse: collapse;
    border: 0.2em solid #ffa500;
    padding: 0.1em;
}


/* @end */

/* @group Search results page */

fieldset#search-field {
    border: none !important;
    text-align: center;
}
input.searchPage {
    font-size:200% !important;
}
#rss-subscription {
    float: left;
    line-height: 3em;
}
#search-results-bar .actionMenuContent {
    margin-top: 1em;
    padding: 0;
}
#search-filter {
    background: #ddd;
    margin: 0;
    padding: 0;
}
#content #search-filter fieldset {
    border: none !important;
    position: relative;
    padding: 0;
    margin: 0;
}
#content #search-filter legend {
    background: transparent;
    font-size: 1.1em;
    padding: 0.5em 0.9em;
    font-weight: bold;
}
#search-filter .field {
    background: #eee;
    padding: 1em 1.5em;
    margin: 0;
}
#search-filter .field label {
    font-weight: normal;
}
#search-filter .formControls {
    text-align:center
}
form.searchPage h1.documentFirstHeading {
    font-weight:normal;
    letter-spacing:0;
    float: left;
    margin-right: 0.8em;
}
form.searchPage h1.documentFirstHeading .highlightedSearchTerm {
    background: transparent !important;
}
#search-results-bar > * {
    float: left;
}
#searchResultsSort{
    float:right;
}
#search-results-bar {
    position: relative;
    z-index: 1;
    float: left;
    width: 100%;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    padding: 1em 0;
}
#search-results-bar dl.actionMenu {
    margin-bottom: 0;
}
#search-results-bar dd.actionMenuContent {
    margin-left: 0;
    padding-left: 0;
}
#search-results-bar dt.actionMenuHeader a {
    border-bottom: none;
}
#search-results-bar dt.actionMenuHeader a::after {
    content: " ▼";
}
#ajax-search-res {
    display: none;
}

/* @end */


/* @group Dashboard */

#dashboard-actions ul,
#dashboard-actions ul li {
    list-style: none;
}
#dashboard-actions ul {
    margin-left: 0;
}

#dashboard {
    font-size: 125%;
    line-height: 1em;
}

#dashboard dd {
    margin: 0;
}


#dashboard dl.portlet a:link {
    border-bottom: none;
}

#dashboard-portlets1,
#dashboard-portlets2,
#dashboard-portlets3 {
    float:left;
    width:23%;
    padding:0.7em 1.3em 0 0;
}

#dashboard-portlets4 {
    float:left;
    width:23%;
    padding-top:0.7em;
}

#dashboard-portlets1 dl.portlet,
#dashboard-portlets2 dl.portlet,
#dashboard-portlets3 dl.portlet,
#dashboard-portlets4 dl.portlet {
    margin-bottom:1.5em;
}

/* @end */

/* @group Forms */

.editionComment {
    line-height:1.5em;
    margin:1em 0;
    padding:0 1em 1em;
    width:auto;
}

/* Emulates the styling from the h1 tag */
#content .field input[type="text"][name$=".title"],
#archetypes-fieldname-title input {
    font-family: "Helvetica Neue", Arial, FreeSans, sans-serif;
    line-height: 125%;
    color: Black;
    letter-spacing: -0.05em;
    margin: inherit -0.05em;
    font-size: 2em; /* 26px equiv */
    font-weight: bold;
}

/* Emulates styling from the description's view */
#content .field textarea[name$=".description"],
#archetypes-fieldname-description textarea#description {
    font-family: Arial,FreeSans,sans-serif;
    color:#666666;
    font-size:1.25em;
    font-weight: bold;
}


div.formControls {
    margin: 1em;
}
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
    background-color: #ffffe0;
}

#content optgroup {
    font-style: normal;
    font-weight: bold;
    color: #999;
    padding-left: 0.25em;
}
#content optgroup > option {
    padding: 0 0.25em 0 1em;
}
dl.enableFormTabbing dd {
    margin-left: 0;
    padding-top: 2em;
}
.searchSection {
    color: #76797c;
    margin-top: 0.25em;
}
.searchSection label:hover {
    color: Black;
}
.field {
    clear: both;
    margin-bottom: 1em;
}
.field input[type="text"] {
   width: 20em;
}
.field input[type="text"][size] {
   width: auto;
}
.field input[type="text"][name$=".title"],
input#title {
    width: 100%;
}
.field.error input[type="text"][name$=".title"],
.error input#title {
    width: 99%;
}
.fieldUploadFile {
    text-align: right;
    margin-right: 0.75em;
    display: none;
}
.fieldTextFormat {
    text-align: right;
    margin-right: 0.75em
}
.formHelp {
    color: #555;
    margin: 0 0 0.2em 0;
    display: block;
    font-weight: normal;
}
div.field.error {
    background-color: #fdc;
    border:  1px solid red;
    padding: 0.5em;
    margin:  1em 0;
}
.error .fieldRequired {
    color: #d00;
}
div.error.warning {
    background-color: transparent;
    border: none;
    margin-top: 0;
    padding: 0;
}

div.error.warning input {
    border: 2px solid red;
}

input.inputLabelActive {
    color: #76797c;
}
textarea#form\.description {
    height: 6em;
    width: 99%;
}
table.listing tbody tr.odd.selected td,
table.listing tbody tr.odd.dragging td,
tr.dragging,
tr.selected {
    background-color: #ffa;
}
table.listing tbody tr.odd.error td,
tr.error {
    background-color: #fdc;
}
.multi-widget-field {
    padding-left: 0.5em;
    border-left: 1.5em solid #DDD;
}

.multi-widget-checkbox {
    margin-left: -2em;
    margin-right: 0.5em;
    width: 1.5em;
    display: inline-block;
}

/* @group Login form */

body.template-logged_out #content,
body.template-login_form #content,
.pb-ajax #login-form {
    text-align: center;
}

#fieldset-openid-login input, #login-form input {
    font-size: 150%;
}


body.template-register #content input,
body.template-new-user #content input {
    font-size: 150%;
    width: inherit;

}

#fieldset-openid-login label, #login-form label {
    margin-right:0.5em;
    padding-top:0.2em;
    text-align:right;
    width: 10em;
    display: inline-block;
}

#fieldset-openid-login .formControls input, #login-form .formControls input {
margin-left: 0.25em;
}

/* @end */

/* @end */

/* @group Sitemap */

#portal-sitemap {
    list-style: none;
    margin-left: 0;
    font-size: 130%;
    font-weight: bold;
}

#portal-sitemap li {
    list-style: none;
    margin-left: 0;
}

#portal-sitemap a {
    border-bottom: none;
    display: block;
}

#portal-sitemap > li {
    border-bottom:0.1em dotted #ddd;
    line-height:1.8em;
    padding:0.25em 1em;
    margin-bottom: 0;
}

#portal-sitemap .navTreeLevel2,
#portal-sitemap .navTreeLevel2 ul {
    font-size: 85%;
    font-weight: normal;
    margin-bottom: 0;
    padding: 0.25em 0;
}

/* @end */

/* @group Album view classes */
.photoAlbumEntry {
    float: left;
    position:relative;
    overflow: hidden;
    height: 185px;
    width: 143px;
    margin: 0em;
    padding: 0px 6px 0px 9px;
    text-align: center;
    background-image: url('polaroid-single.png');
    background-repeat: no-repeat;
}
.photoAlbumEntry img {
    border: 1px solid #ccc;
    display: block;
    margin: 0 auto;
}
.photoAlbumEntryWrapper {
    /*display: block;*/
    height: 130px;
    width: 128px;
    margin-bottom: 7px;
}
.photoAlbumEntry a {
    display: block;
    text-decoration: none;
    font-size: 90%;
    height: 169px;
    width: 130px;
    margin: 16px auto 0px;
}
.photoAlbumFolder {
    background-image: url('polaroid-multi.png');
    background-repeat: no-repeat;
}
.photoAlbumEntryTitle {
    color: #76797c;
    display: block;
    width: 128px;
    height: 3.6em;
}

/* @end */

/* @group Invisibles */

.hiddenStructure {
    display: block;
    background: transparent;
    background-image: none; /* safari bug */
    border: none;
    height: 0.1em;
    overflow: hidden;
    padding: 0;
    margin: -0.1em 0 0 -0.1em;
    width: 1px;
}
/* ensures hidden elements don't take up space */
/* .ie6 is set on <html> by Modernizr library */
.ie6 .hiddenStructure,
.ie7 .hiddenStructure {
    position: absolute;
}

#content .visualNoMarker {
    list-style:none;
    margin-left: 0;
}

.visualClear {
    clear: both;
}

/* @end */

/* @group Accessibility elements, applied by JS */

body.largeText { font-size: 125%; }
body.smallText { font-size: 85%; }

/* @end */

/* @group Overlay windows */

/* styling for overlays (popups) */

div.overlay {
    width: auto;
    height: auto;
    /* initially overlay is hidden */
    display: none;
    /* some padding to layout nested elements nicely  */
    margin: 1em;
    font-size: 80%;
}

div.overlay-iframe,
div.overlay-ajax {
    width: 60%;
    min-height: 100px;
    z-index: 99; /* overlays should be above everything */
}

div.overlay-iframe {
    height: 60%;
}

/* default close button positioned on upper-left corner */
div.overlaybg div.close,
div.overlay div.close {
    background-image: url(pb_close.png);
    position: absolute;
    left: -14px;
    top: -14px;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.pb-ajax {
    overflow-y: auto;
}

.pb-ajax, .pb-image, .overlay iframe {
    background-color: #fff;
    border: 1px solid #999;
    white-space: normal;
    box-shadow: 0 0 3em 0.5em #666;
    -moz-box-shadow: 0 0 3em 0.5em #666;
    -webkit-box-shadow: 0 0 3em #666;
}

.pb-ajax > div {
    width: 92%;
    padding: 1em;
}

.pb-ajax .documentActions {display:none}

div.overlay div.close span {
    display: block;
    height: 1px;
    margin: -1px 0 0 -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
}

div.overlay textarea { width: 100%; }

/* Hide form elements that don't make sense within an overlay. */
div.overlay .link-parent { display: none; }
div.overlay #createGroup legend { display: none; }
div.overlay #createGroup fieldset { border: none; }

/* lets play nice with paragraphs, links, tabbed pages in overlay e.g. when plone.openid is installed */
div.overlay p {
    margin-bottom: 1em;
}
div.overlay label {
    margin-bottom: 0.25em;
}
div.overlay a:hover {
    color: #75ad0a;
}
div.overlay a:hover label{
    cursor: pointer;
}
div.overlay a.selected {
    color: #000000;
}
div.overlay .formTab {
    margin-right:1em;
    font-weight: bold;
}
div.overlay .formTab.lastFormTab {
    margin-right:0em;
}
div.overlay .formControls a {
    display: block;
    margin: 0.75em;
}

/* @end */

/* @group Content History */

#history dd {
}

div.historyRecord {
    margin-bottom: 1em;
}


div.historyTools ul {
    float: right;
    margin: 2px 1em;
}

div.historyTools ul li {
    display: inline;
    padding: 0 0.015em;
}

div.historyTools ul li a{
    border-bottom: none;
}

div.historyTools form {
    display: inline;
}

.historyAction {

}

.historyByLine {
    background: #ddd;
    padding: 0.5em;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    -webkit-border-top-right-radius: 0.5em;
    -webkit-border-top-left-radius: 0.5em;
    -moz-border-radius-topleft: 0.5em;
    -moz-border-radius-topright: 0.5em;
}

div.historyLinks {
    float: right;
    clear: right;
    margin: 0.5em 1em 0 0;
}


div.historyComment {
    padding: 0.5em 1em;
    border: 1px solid #ddd;
}

div.historyComment p {
    margin: 0;
    padding-top: 1px;
}

a.historyComparePrevious {
    border-top: 1px dotted #ccc;
    display: block;
    margin-top: 1.5em;
    margin-bottom: -0.5em;
    text-align: center;
}

a.historyComparePrevious span {
    background-color: white;
    position: relative;
    top: -0.75em;
    padding: 0 0.5em;
}

/* @end */

/* @group Core content type sprites */

/* Generated using http://spritegen.website-performance.org, with the following
   parameters:
   - Build direction: Vertical
   - Horizontal offset: (doesn't matter, but can't be 0)
   - Vertical offset: 200px
   - Compress image with OptiPNG: yes
   - Class prefix: contenttype-
   - Display width and height: no
  Then, add 4 px to the background-position to line up the icons to text.

.contenttype-document_icon { background-position: 0 0;  }
.contenttype-event_icon { background-position: 0 -216px;  }
.contenttype-file_icon { background-position: 0 -432px;  }
.contenttype-folder_icon { background-position: 0 -648px;  }
.contenttype-image_icon { background-position: 0 -864px;  }
.contenttype-link_icon { background-position: 0 -1080px;  }
.contenttype-newsitem_icon { background-position: 0 -1296px;  }
.contenttype-site_icon { background-position: 0 -1512px;  }
.contenttype-topic_icon { background-position: 0 -1728px;  }

*/

.icons-on .contenttype-document {
    background: no-repeat transparent 0px 4px url(contenttypes-sprite.png);
}
.icons-on .contenttype-event {
    background: no-repeat transparent 0px -212px url(contenttypes-sprite.png);
}
.icons-on .contenttype-folder {
    background: no-repeat transparent 0px -644px url(contenttypes-sprite.png);
}
.icons-on .contenttype-link {
    background: no-repeat transparent 0px -1076px url(contenttypes-sprite.png);
}
.icons-on .contenttype-news-item {
    background: no-repeat transparent 0px -1292px url(contenttypes-sprite.png);
}
.icons-on .contenttype-plone-site {
    background: no-repeat transparent 0px -1508px url(contenttypes-sprite.png);
}
.icons-on .contenttype-collection {
    background: no-repeat transparent 0px -1724px url(contenttypes-sprite.png);
}
.icons-on .contenttype-topic {
    background: no-repeat transparent 0px -1724px url(contenttypes-sprite.png);
}

/* These should only appear in the add menu */
.icons-on #plone-contentmenu-factories .contenttype-file {
    background: no-repeat transparent 0px -428px url(contenttypes-sprite.png);
}
.icons-on #plone-contentmenu-factories .contenttype-image {
    background: no-repeat transparent 0px -860px url(contenttypes-sprite.png);
}

.icons-on .contenttype-document,
.icons-on .contenttype-event,
.icons-on .contenttype-folder,
.icons-on .contenttype-link,
.icons-on .contenttype-news-item,
.icons-on .contenttype-plone-site,
.icons-on .contenttype-collection,
.icons-on .contenttype-topic,
.icons-on #plone-contentmenu-factories .contenttype-file,
.icons-on #plone-contentmenu-factories .contenttype-image {
    line-height: 2em;
    display: inline-block;
    *display: block;
    padding-left: 20px !important;
}

/* @end */

/* @group jQuery Tag multiple selector */
.optionsBox {
    overflow-y: auto;
    border: solid 1px #999999;
    background: #FFFFFF;
    height: 14em;
    width: 100%;
}
/* IE7: fixes super-wide div for JavaScript Tag selector */
/* .ie7 is set on <html> by Modernizr library */
.ie7 div.optionsBox {
    width: 50%;
}
.optionsBox LABEL {
    padding: 2px 0 0 0;
    display: block;
    line-height: 1em;
    font-weight: normal;
}
.optionsBox input{
    vertical-align: middle;
    margin: 1px 4px 4px 6px;
}
/* IE7: fixes super-wide div for JavaScript Tag selector */
/* .ie7 is set on <html> by Modernizr library */
.ie7 .optionsBox input {
    margin: 0px;
}
.optionsBox LABEL.checked {
    background-color: #EEEEEE;
}
.optionsBox LABEL.hover {
    background-color: #777777;
    color: #FFFFFF;
}
#archetypes-fieldname-subject .formHelp {
    font-weight: normal;
}
#existingTagsSection {
    position: relative;
    margin-left: 3em;
}
#existingTags {
    float: left;
    width: 50%;
}
#content #existingTags dd {
    margin-bottom: 1px;
    margin-left: 0;
}
#content #selectedTags, #content #newTags {
    margin-bottom: 0;
    margin-left: 0;
}
#content #selectedTagsSection {
    margin-left: 3em;
    float: left;
    width: 43%;
    margin-bottom: 0;
}
#newTagsSection {
    width: 48%;
    margin-left: 3em;
}
#content p.selectedTag {
    margin-bottom: .4em;
}
#existingTagsTitle, #newTagsTitle {
    float: left;
}
#existingTagsHelp, #newTagsHelp {
    float: right;
}
#noTagsSelected, #oneOrMoreTagsSelected {
    display: none;
}
/* @end */

}